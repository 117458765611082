$flash-bleu: rgba(233,233,239, 0.45);
$flash-bleu-dark: rgba(43,36,67, 0.45);

$flash-blue-dark: #2B2443;


.top-bar {
/*   background-color: white; */
/*   display: flex; */
/*   width: 100%; */
  .menu {
/*   position: fixed;  */
/*   top: 0; */
/*   z-index: 30; */

  

  }  
}


.data-sticky-container {
 z-index: 50;
  
}


.top-bar {
 background-color:  $flash-blue-dark;
 .menu {
    background-color:  $flash-blue-dark;
 }
 color: white;
 a {
  color: white; 
 }
 
 a:hover {
   //font-weight: bold; 
   color: rgba(230, 230, 230, 1);
   
    
  }
 
 
 z-index: 50;
  
}

.sticky.is-stuck {
 z-index: 50; 
}

.all-body {
 //margin-top: 24px;
  
  background: url('../img/icon.svg');
  //background: url('../img/icon_wp.svg');
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover; 
  
  @include breakpoint(medium){    
  //background: url('../img/hero.svg');
    background: url('../img/hero_wp.svg');
    background-position: left center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover; 
  }
  
/*   background: url('../img/hero.svg'); */
//    background: url('../img/hero_wp.svg');

 
 
 
 //padding-left: 10%; 
// padding-right: 10%; 
 background-color: #F5F5F5;
 width: 100%;
 display: flex;
 flex-direction: column;
     
 
  
}


$hero-height: 80vh;

.hero-section 
{
  //background: url('../img/hero.svg');
  //background-size: cover;

  //background: url('../img/hero_wp.svg');
  //background-size: cover;

  background-color: #D5D5DA;
  height: $hero-height;
  //text-align: right;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  
  .logo {   
    height: $hero-height;
    min-height: 70vh;
    max-width: 10vw;    
  }

}


.cover {
  min-height: 90vh;
  display: flex;  
  
  .left {    
    @include breakpoint(medium){    
      width: 25%;    
    }
  }
  
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    background-color: rgba(245, 245, 245, 0.5);    
    //width: 75%;
    padding-left: 1em;
    padding-right: 1em;
    h2 {
     text-align: center; 
    }
    @include breakpoint(medium){    
      width: 75%;
      padding-left: 0em;
      padding-right: 0em;
      
    }
  }
  
}


.text {
   p {
    text-align: justify;    
  }  
  
  
  img.wrap {
    @include breakpoint(large){    
      width: 45%;
      float: right;    
      padding: 1em;
    }
  }  
  
  
  .images-with-legend {
      display: flex; 
      flex-direction: column;
      align-items: center;
      p {
      text-align: center;    
      }
    
      .images {
	display: flex; 
	justify-content: center;
	max-width: 100%;
	
	.item {
	  display: flex; 
	//  justify-content: center;
	  flex-direction: column;
	  align-items: center;
	  padding-left: 1em;
	  padding-right: 1em;	  
	}     
   }    
  }  
}    

  .hero-section-text {
    color: $flash-bleu-dark;    
  }


.hero {
  min-height: 30vh;
  padding: 5vw;
  @include breakpoint(medium)
  {
    padding-left: 10vw;    
    padding-right: 10vw;    
  }
  
  @include breakpoint(large)
  {
    padding-left: 20vw;    
    padding-right: 20vw;    
  }
  //padding: 10rem;
  //border-radius: 5px;
  //box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  h2 {
    text-align: center;
    padding-bottom: 2em;
  }
}

.steps {
 text-align: center; 
 color: #E9E9EF;
 background-color: $flash-blue-dark;
  h5 {
    margin-bottom: 0;
  }
}


.news {
  background-color: rgba(229, 171, 23, 1);;  
  color: #E9E9EF;  
  .news-item   
  {
    
    h3 {
      text-align:  center;
      @include breakpoint(medium) {
	text-align:  left;
      }
    }
    
    .corps{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-evenly;   
   
    @include breakpoint(medium) {
      flex-direction: row;
    }
   
   .image {
     padding: 1em;     
    @include breakpoint(medium) {
      width: 25%; 
    }
     
    
    img {     
    }
     
   }
   
   .text {
    width: 75%;      
   }
    
    
  }

  }
  
}

.flash-prize {
  background-color: #B2ADE9;
}

.overview{
  background-color: rgba(255, 255, 255, .8);

   

  
}

.mccoy-prize {
   background-color: #0D6CBE;
   color: white;
}

.sponsors {
  background-color: rgba(255, 255, 255, .8);;
  .cell {
    max-height:10%;
    max-width:25%;
    justify-content: center;
    display: flex;    
    img {
     max-height: 6em; 
    }
  }
}

.endorsements
{
  
  .logos
  {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    //background-color: white;
    //outline: solid 1px blue;
    align-content: center;
    justify-content: space-evenly; 

    .item {
      img {
	height: 10em;
	padding: .5em;
      }

    }
    
  }
  
  ul {
  padding: 1em;
    @include breakpoint(medium) {
      columns: 2;
    }
  }
  
  
  .container_map
  {
    flex-basis: 50vw; 
   
    display: flex; 
   //flex-direction: column;
    justify-content: center; 
/*    align-items: center;    */
  
   
   #map {
    z-index: 10;
    height: 50vh;
    width: 100%;;
    position: relative;    
   }
   
    
  }
  
  
   background-color: rgba(255, 255, 255, .8);;
   .company {
     
   }
   
   h3 {
     padding-top: 3em;     
    text-align: center; 
   }
   
  .cell {
    max-height:10%;
    max-width:25%;
    justify-content: center;
    display: flex;
    
    img {
     max-height: 6em; 
    }
  }
  
}

.footer {
 background-color: $flash-blue-dark; 
 min-height: 10vh;
 
 .disclaimer{
  text-align: center; 
  color: white;
 }
 
}



